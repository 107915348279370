<template>
    <div class="carryOn">
        <div class="tab">
            <span @click="tabNum=1" :class="{active:tabNum===1}">我要存件</span>
            <span @click="tabNum=2" :class="{active:tabNum===2}">存件记录</span>
        </div>
        <div v-if="tabNum===1" class="carryOn_list">
            <div v-for="item in list" :key="item.label" class="item" @click="goTo(item)">
                {{ item.deptName }}
            </div>
        </div>
        <div v-else class="carryOn_record">
            <template v-if="list1.length===0">
                <van-empty description="暂无存件记录" />
            </template>
            <template v-else>
                <div v-for="(item,index) in list1" :key="index" class="item">
                    <div class="header">
                        <span>{{ item.deptName }}</span>
                        <span :class="{over:item.pickupTime}">{{ item.pickupTime?'已取件':'待取件' }}</span>
                    </div>
                    <div class="body">
                        <div v-for="iSon in model" :key="iSon.key">                 
                            <span class="label">{{ iSon.label }}</span>
                            <span class="value">{{ item[iSon.key] }}</span>
                        </div>
                    </div>
                </div>
            </template>
        </div>
    </div>
  </template>
  
  <script>
  import {getDept,getStorageRecord} from '@/api/carryOn.js'
  import { Image,Toast,Dialog,Empty } from "vant";
  import { mapState } from "vuex";
  
  export default {
    name: "carryOn",
    components: {
      [Image.name]: Image,
      [Empty.name]:Empty,
      [Dialog.Component.name]: Dialog.Component,
    },
    computed: {
      ...mapState({
        userInfo: (state) => state.userInfo,
      }),
      isMiniprogram(){
        return window.__wxjs_environment === 'miniprogram' 
      }
    },
    data() {
      const model=[
        {label:'存件时间：',key:'storageTime'},
        {label:'文件数量：',key:'fileNum'},
        {label:'取件人姓名：',key:'pickupPerson'},
        {label:'取件人联系方式：',key:'pickupPhone'},
        {label:'取件时间：',key:'pickupTime'},
      ]
      return {
        model,
        tabNum:1,
        list:[],
        list1:[]
      };
    },
    mounted() {
        this.getDeptList();
    },
    methods: {
        async getDeptList(){
            {
                const {data} = await getDept();
                this.list=data.records;
            }
            {
                const params = {
                    phone:this.userInfo.phone,
                    type:0
                }
                const {data} = await getStorageRecord(params);
                this.list1=data.data;
            }
        },
        goTo(item){
            this.$router.push({name:'checkNum',query:{deptId:item.id,deptName:item.deptName}})
        }
    },
  };
  </script>
  
  <style lang="scss" scoped>
  .carryOn {
    width: 100%;
    background: #f5f7fa;
    // padding:0 10px 12px;
    .tab{
        display: flex;
        justify-content: space-around;
        height: 40px;
        font-size: 14px;
        background-color: #fff;
        margin-bottom: 12px;   
        span{
            color: #696B6F;
            // flex: 1;
            display: flex;
            justify-content: center;
            align-items: center;
            border-bottom: 2px solid #fff;
            &.active{
                color: #1191FC;
                border-bottom: 2px solid #1191FC;
            }
        }
    }
    .carryOn_list{
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        align-content: flex-start;
        font-size: 14px;
        color: #1B2845;
        padding: 0 10px;
        height: calc(100vh - 64px);
        overflow: auto;
        .item{
            height: 68px;
            width: calc(50% - 6px);
            margin-bottom: 14px;
            line-height: 1.2;
            display: flex;
            align-items: center;
            padding:0 16px;
            background-color: #ECF6FF;
            background-image: url('~@/assets/carryOn/itemBg.png');
            background-repeat: no-repeat;
            background-position: right bottom;
            background-size:46px 50px;
        }
    }
    .carryOn_record{
        padding: 0 10px;
        height: calc(100vh - 64px);
        overflow: auto;
        .item{
            background-color: #fff;
            border-radius: 4px;
            +.item{
                margin-top: 12px;
            }
            .header{
                display: flex;
                justify-content: space-between;
                align-items: center;
                padding: 14px 12px 14px 18px;
                border-bottom: 1px solid rgba($color: #000000, $alpha: 0.1);
                span:nth-of-type(1){
                    color: #1B2845;
                    font-size: 18px;
                    line-height: 22px;
                    border-left: 4px solid #1989F7;
                    padding-left: 8px;
                }
                span:nth-of-type(2){
                    color: #fff;
                    font-size: 14px;
                    padding: 0 8px;
                    line-height: 24px;
                    border-radius: 2px;
                    background-color: #F1B800;
                    &.over{
                        background-color: #7DD431;
                    }
                }
            }
            .body{
                font-size: 14px;
                padding: 14px 12px 14px 18px;
                >div{
                    .label{
                        color: rgba($color: #333333, $alpha: 0.4);
                    }
                    .value{
                        color: rgba($color: #1B2845, $alpha: 0.8);
                    }
                    +div{
                        margin-top: 10px;
                    }
                }
            }
        }
    }
  }
  </style>