import request from '../common/request';

// 获取申报号
export const addStorageRecord = (params) => request({
  url: '/api/storagerecord',
  method: 'post',
  data:{
    ...params
  }
});

// 部门列表
export const getDept = (params) => request({
  url: '/api/storagedept/page',
  method: 'get',
  params:{
    ...params,
    current:1,
    size:1000,
  }
});
//存件记录 /storagerecord/getRecordByTypeAndPhone
export const getStorageRecord = (params) => request({
    url: '/api/storagerecord/getRecordByTypeAndPhone',
    method: 'get',
    params:{
      ...params,
    }
  });

